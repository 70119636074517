import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { SidebarService } from 'src/app/services/service.index';
import { UsersService } from '../../services/pages/users/users.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  toggle:boolean;
  visible:boolean;
  isShrinked:boolean;

  constructor(
    public _sidebar: SidebarService,
    public usersService: UsersService,
    private router: Router,
  ) { 
    if(localStorage.getItem('ABCADMIN_CONFIG')){
      let isShrinked = JSON.parse(localStorage.getItem('ABCADMIN_CONFIG')).isShrinked;
      if(isShrinked){
         this.visible = true;
      }else{
         this.visible = false;
      }
    }
  }

  ngOnInit() {
    this._sidebar.loadMenu();
    this.toggle = false;
  }
  
  clickToggle(){ 
    this.toggle = !this.toggle;

    let userAgent = navigator.userAgent || navigator.vendor;
    if(/android/i.test(userAgent)){
      if(this.toggle){
        document.getElementById("btn-sidebar-wrapper").style.display = "block";
      }else{
        document.getElementById("btn-sidebar-wrapper").style.display = "none";
      }
    }
     
    if(this.toggle){
      this.toggle = true;
      this.visible = true;
      let body = document.getElementsByTagName("body")[0];
      body.setAttribute("data-theme-sidebar-shrinked", 'true');
      let defaultConfig = {
        fixedFooter: false,
        fixedHeader: false,
        fixedLeftSidebar: true,
        isShrinked: true,
        themeColor: "app-theme-megaflor",
        themeMode: "default-mode",
      };
      localStorage.setItem('ABCADMIN_CONFIG', JSON.stringify(defaultConfig));
    }else{
      this.toggle = false;
      this.visible = false;
      let body = document.getElementsByTagName("body")[0];
      body.setAttribute("data-theme-sidebar-shrinked", 'false');
      let defaultConfig = {
        fixedFooter: false, 
        fixedHeader: false,
        fixedLeftSidebar: true,
        isShrinked: false,
        themeColor: "app-theme-megaflor",
        themeMode: "default-mode",
      };
      localStorage.setItem('ABCADMIN_CONFIG', JSON.stringify(defaultConfig));
    }
  }

  public inicio(){
    return this.router.navigateByUrl('/home');
  }



}
