import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

// Interfaces 
import { Pedidos, DetallePedido } from '../../../interfaces/interfaces.index';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  public notificacion = new EventEmitter<any>();
  public notificacionCaja = new EventEmitter<any>();
  public notificacionOrden = new EventEmitter<any>();
  public notificacionRango = new EventEmitter<any>();
  public notificacionAnular = new EventEmitter<any>();

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private toastr: ToastrService,
  ) { }
  
  //ordenes a la fecha hoy
  public getAlls(): Observable<any> {
    return this.http.get<Pedidos[]>(`${environment.url}api/v1/orders`, { responseType: 'json' });
  }

  public billedOrders(): Observable<any> {
    return this.http.post(`${environment.url}api/v1/orders/billedorders`, { responseType: 'json' });
  }

  public getAllOrdersDate(model:any): Observable<any> {
    return this.http.post<Pedidos[]>(`${environment.url}api/v1/orders/date`, model, {observe: 'response'});
  }

  public getOne(id: number) {
      return this.http.get<Pedidos>(`${environment.url}api/v1/orders/${id}`, {observe: 'response'})
      .pipe(
          map((resp: any) => {
              return resp.body.message;
          }),
          catchError(
              error => {
                  Swal.fire(`Error ${error.status}`, error.error.message, 'error');
                  this.notificacion.emit(error);
                  return throwError(error.error.status);
              }
          ),
      );
  }

  public getRango(model:any) {
      return this.http.post(`${environment.url}api/v1/orders/rango`, model, {observe: 'response'})
      .pipe(
          map((resp: any) => {
            this.notificacionRango.emit(resp); 
            return resp.body.message;
          }),
          catchError(
            error => {
              Swal.fire(`Error ${error.status}`, error.error.message, 'error');
              this.notificacionRango.emit(error);
              return throwError(error.error.status);
            }
          ),
      );
  }

  public getRangoByCustomer(model:any) {
      return this.http.post(`${environment.url}api/v1/orders/rangocustom`, model, {observe: 'response'})
      .pipe(
          map((resp: any) => {
            this.notificacionRango.emit(resp); 
            return resp.body.message;
          }),
          catchError(
            error => {
              Swal.fire(`Error ${error.status}`, error.error.message, 'error');
              this.notificacionRango.emit(error);
              return throwError(error.error.status);
            }
          ),
      );
  }

  public getFiltrar(model:any) {
      return this.http.post(`${environment.url}api/v1/orders/filtrar`, model, {observe: 'response'})
      .pipe(
          map((resp: any) => {
            this.notificacionRango.emit(resp); 
            return resp.body.message;
          }),
          catchError(
            error => {
              Swal.fire(`Error ${error.status}`, error.error.message, 'error');
              this.notificacionRango.emit(error);
              return throwError(error.error.status);
            }
          ),
      );
  }

  public getOneFull(id: number) {
      return this.http.get<Pedidos>(`${environment.url}api/v1/orders/full/${id}`, {observe: 'response'})
      .pipe(
          map((resp: any) => {
              return resp.body.message;
          }),
          catchError(
            error => {
              Swal.fire(`Error ${error.status}`, error.error.message, 'error');
              this.notificacion.emit(error);
              return throwError(error.error.status);
            }
          ),
      );
  }

  public create(model: Pedidos) {
    return this.http.post<Pedidos[]>(`${environment.url}api/v1/orders`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
          this.toastr.success('El Pedido ha sido creado exitosamente!', 'Bien!');
          return resp;
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status); 
        }),
      );
  }

  public editar(model: Pedidos) {

    return this.http.put(`${environment.url}api/v1/orders/${model.id}`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (resp.body.message.status == 1) {
            Swal.fire('Editar Pedidos', 'El Pedido ha sido editado, exitosamente', 'success');
          }
          return this.router.navigateByUrl('/sales');
        }),
        catchError(error => this.errorHandlerService.showError(error))
      );
  }

  public anular(id: number, status: number) {
    return this.http.put(`${environment.url}api/v1/orders/${id}`, { status: status }, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacionAnular.emit(id);
          if (status == 0) {
            this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
            this.toastr.success('El Pedido ha sido Anulado exitosamente!', 'Bien!');
          }
        }),
        catchError(
          (error:any) => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.message);
          }
        ),
      );
  }

  public actualizar(id: number, modelo: any) {
    return this.http.put(`${environment.url}api/v1/orders/${id}`, modelo, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
          this.toastr.success('Campo actualizado exitosamente!', 'Bien!');
          return resp;
        }),
        catchError(
          (error:any) => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.message);
          }
        ),
      );
  }

  //desactivar todos los campos del formulario 
  public blockForm(models:any) {

      let modelo:any = {
        facturando: models.facturando,
        secuencial: models.secuencial,
        billing_status: models.billing_status,
        status: models.status,
        emission_point: models.emission_point,
        rucEmpresa: models.rucEmpresa,
        ambiente: models.ambiente,
        codBussines: models.codBussines,
        legalid_tc: models.legalid_tc,
        legalid_pe: models.legalid_pe,
        departure_date: models.departure_date
      }

    return this.http.put(`${environment.url}api/v1/orders/${models.id}`, modelo, {observe: 'response'})
      .pipe(
        map((resp: any) => {
          if(models.billing_status == 0){
            resp.accion = false;
            this.notificacionOrden.emit(resp);
          }else{
            resp.accion = true;
            this.notificacionOrden.emit(resp);
          }
          
          if(models.billing_status == 0){
            this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
            this.toastr.success('La factura ha sido restaurada!', 'Bien!');
          }else{
            this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
            this.toastr.success('La factura ha sido procesada!', 'Bien!');
          }
        }),
        catchError(
          error => {
            //console.log(error.code);
            Swal.fire(`Error`, 'La Clave de autorización está incompleta, verifique los campos de envio.', 'error');
            //this.notificacionOrden.emit(error);
            return throwError(error.error.message);
          }
        ),
      );
  }

   public groupDae(id: number, datos: any) {
    return this.http.put(`${environment.url}api/v1/orders/${id}`, datos, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          console.log('Actualizados campos grupo dae');
        }),
        catchError(
          (error:any) => {
            Swal.fire(`Error ${error.mixta}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.message);
          }
        ),
      );
  }

  //Detalles ordenes
  public getAllDetails(idOrden:number): Observable<any> {
    return this.http.get<DetallePedido[]>(`${environment.url}api/v1/details/order/${idOrden}`, { responseType: 'json' });
  }

  public getOneDetails(id: number) {
      return this.http.get<DetallePedido>(`${environment.url}api/v1/details/${id}`, {observe: 'response'})
      .pipe(
          map((resp: any) => {
              return resp.body.message;
          }),
          catchError(
              error => {
                  Swal.fire(`Error ${error.status}`, error.error.message, 'error');
                  this.notificacion.emit(error);
                  return throwError(error.error.status);
              }
          ),
      );
  }

  //Box detalles 
  public createBox(model: DetallePedido) {
    return this.http.post<DetallePedido[]>(`${environment.url}api/v1/details`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacionCaja.emit(resp);
          this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
          this.toastr.success('Caja creada exitosamente!', 'Bien!');
          return resp;
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public editDetalles(model: DetallePedido) {
    
    return this.http.put(`${environment.url}api/v1/details/${model.id}`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (resp.body.message.status == 1) {
            this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
            this.toastr.success('Caja editada exitosamente!', 'Bien!');
          }
          //return this.router.navigateByUrl('/sales');
        }),
        catchError(error => this.errorHandlerService.showError(error))
      );
  }

  public actualizarOne(id: number, modelo: any) { 
    return this.http.put(`${environment.url}api/v1/details/one/${id}`, modelo, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
          this.toastr.success('Campo actualizado exitosamente!', 'Bien!');
        }),
        catchError(
          (error:any) => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.message);
          }
        ),
      );
  }

  public simpleMixta(id: number, mixta: number) {
    let datos = {
      mixta: mixta,
      campo: "mixta", 
      status: 2
    }
    return this.http.put(`${environment.url}api/v1/details/one/${id}`, datos, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (mixta == 0) {
            this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
            this.toastr.success('Marcada como Caja Simple!', 'Bien!');
          } else if(mixta == 1){
            this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
            this.toastr.info('Marcada como Caja Mixta!', 'Bien!');
          }
        }),
        catchError(
          (error:any) => {
            Swal.fire(`Error ${error.mixta}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.message);
          }
        ),
      );
  }

  public removeTable(id:number): Observable<any> {
    return this.http.delete<DetallePedido[]>(`${environment.url}api/v1/details/${id}`, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacionCaja.emit(resp);
          this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
          this.toastr.success('Caja eliminada!', 'Ok!');
        }),
        catchError(
          (error:any) => {
            Swal.fire(`Error ${error.mixta}`, error.error.message, 'error');
            this.notificacionCaja.emit(error);
            return throwError(error.error.message);
          }
        ),
      );
  }

  public getPoins(): Observable<any> {
    return this.http.get(`${environment.url}api/v1/orders/poins/1`, { responseType: 'json' });
  }

}
