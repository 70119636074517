// Pages
export { RoleService } from './pages/roles/roles.service';
export { UsersService } from './pages/users/users.service';
export { ProfileService } from './pages/profile/profile.service';
export { PermisionService } from './pages/permission/permision.service';

export { TypesflowersService } from './pages/crops/typesflowers/typesflowers.service';
export { ColorsService } from './pages/crops/colors/colors.service';
export { VarietiesService } from './pages/crops/varieties/varieties.service';
export { ProjectionsService } from './pages/sales/projections/projections.service';
export { ProjectionsDetailsService } from './pages/sales/projections-details/projections-details.service';
export { AsignationsService } from './pages/sales/asignations/asignations.service';
export { VarietiesDimensionService } from './pages/crops/varieties_dimension/varieties-dimension.service';
export { GeneralService } from './pages/general/general.service';
export { UploadService } from './pages/upload/upload.service';
export { CustomersService } from './pages/customers/customers.service';
export { SalesService } from './pages/sales/sales.service';
export { AirlinesService } from './pages/airlines/airlines.service';
export { FreightAgenciesService } from './pages/freight_agencies/freight-agencies.service';
export { RecipientsService } from './pages/recipients/recipients.service';
export { CoolroomsService } from './pages/coolrooms/coolrooms.service';
export { OrdersService } from './pages/orders/orders.service';
export { SuppliersService } from './pages/suppliers/suppliers.service';
export { StemsService } from './pages/stems/stems.service';
export { BunchService } from './pages/bunch/bunch.service';
export { BanksService } from './pages/banks/banks.service';
export { CitiesService } from './pages/cities/cities.service';
export { CountriesService } from './pages/countries/countries.service';
export { AdditionalsService } from './pages/additionals/additionals.service';
export { InvoicesService } from './invoices/invoices.service';
export { CreditDebitService } from './pages/credit_debit/credit-debit.service';
export { PaymentsService } from './pages/payments/payments.service';
export { BankAccountsService } from './pages/bank_accounts/bank-accounts.service';
export { HomeService } from './pages/home/home.service';
export { ReportService } from './pages/report/report.service';
export { PackingService } from './pages/packing/packing.service';
export { DaeService } from './pages/dae/dae.service';
export { EbillingService } from './pages/ebilling/ebilling.service';
export { CastService } from './pages/cast/cast.service';
export { BatchService } from './pages/batch/batch.service';
export { BatchSowingService } from './pages/batch_sowing/batch-sowing.service';
export { BlocksService } from './pages/blocks/blocks.service';
export { HarvestService } from './pages/harvest/harvest.service';
export { SowingService } from './pages/sowing/sowing.service';
export { PostHarvestService } from './pages/post-harvest/post-harvest.service';
export { WarehousesService } from './pages/warehouses/warehouses.service';
export { PurchaseInvoiceService } from './pages/purchase_invoice/purchase-invoice.service';
export { PurchaseDetailService } from './pages/purchase_detail/purchase-detail.service';
export { ParametersService } from './pages/parameters/parameters.service';
export { SeasonService } from './pages/season/season.service';
// Settings


// Shared
export { ErrorHandlerService } from './shared/error-handler.service';
export { SidebarService } from './shared/sidebar.service';
export { NavbarService } from './shared/navbar.service';

