import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import jwt_decode from 'jwt-decode';

import { Projections } from 'src/app/interfaces/interfaces.index';
import { ErrorHandlerService } from 'src/app/services/shared/error-handler.service';
import { environment } from 'src/environments/environment';

// Interfaces 
import { Usuario } from '../../../../interfaces/interfaces.index';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ProjectionsService {
  public token: string;
  public projections: Projections;
  public usuario: any;
  public notificacion = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { 
    this.loadFromStorage();
  }

  public loadFromStorage() {
    if (localStorage.getItem('secure')) {
      this.token = localStorage.getItem('secure');
      this.usuario = jwt_decode(this.token);
    } else {
      this.token = '';
      this.usuario = null;
    }
  }

  public getAll(): Observable<any> {
    return this.http.get<Projections[]>(`${environment.url}api/v1/projections`, { responseType: 'json' });
  }

  public getAllTotal(): Observable<any> {
    return this.http.get<Projections[]>(`${environment.url}api/v1/projections/totals`, { responseType: 'json' });
  }

  public getAllBySeller(): Observable<any> {
    return this.http.get<Projections[]>(`${environment.url}api/v1/projections/seller/${this.usuario.sub}`, { responseType: 'json' });
  }

  public createProjection(model: Projections){

    model.id_seller = this.usuario.sub;
    model.id_typeflower = Number(model.id_typeflower);

    return this.http.post<Projections[]>(`${environment.url}api/v1/projections`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'La Proyección ha sido creada exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public UploadExcel(model: any) {
 
    return this.http.post<Projections[]>(`${environment.url}api/v1/projections/upload`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'El archivo CSV se ha importado exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public generaCsv(code:any) {

    return this.http.get(`${environment.url}api/v1/projections/genera_csv/${code}`, { responseType: 'json' })
    .pipe(
        map((resp: any) => {
           return resp.message[0].route;
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

}
