import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { Venta } from 'src/app/interfaces/interfaces.index';
import { ErrorHandlerService } from 'src/app/services/shared/error-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) {
    
  }

  public getAll(): Observable<any> {
    return this.http.get<Venta[]>(`${environment.url}api/v1/sales`, { responseType: 'json' })
  }

  public filterVentas(model: any) {
  	model.status = (model.status) ? model.status = "1" : model.status = "0";
    return this.http.post(`${environment.url}api/v1/sales/filter`, model, { observe: 'response' })
           .pipe(
              map((resp: any) => ({ venta: resp.body.message })),
              catchError(error => this.errorHandlerService.showError(error))
            );
  }


}
