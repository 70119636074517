import { Pedidos } from '../interfaces/interfaces.index';
import { ActionReducerMap } from '@ngrx/store';
import * as reducers from './reducers';

export interface AppState {
    pedidos: reducers.PedidosState;
}

export const appReducers: ActionReducerMap<AppState> = {
    pedidos: reducers.pedidosReducer,
};
