import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

// Interfaces 
import { Banks } from '../../../interfaces/interfaces.index';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class BanksService {

  public notificacion = new EventEmitter<any>();

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public getAlls(): Observable<any> {
    return this.http.get<Banks[]>(`${environment.url}api/v1/banks`, { responseType: 'json' });
  }

  public create(model: Banks) {
    return this.http.post<Banks[]>(`${environment.url}api/v1/banks`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'El Banco ha sido creado exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public editar(model: Banks) {

    return this.http.put(`${environment.url}api/v1/banks/${model.id}`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (resp.body.message.status == 1) {
            Swal.fire('Editar Banco', 'El Banco ha sido editado, exitosamente', 'success');
          }
          return this.router.navigateByUrl('/banks');
        }),
        catchError(error => this.errorHandlerService.showError(error))
      );
  }

  public activarDesactivar(id: number, status: number) {
    return this.http.put(`${environment.url}api/v1/banks/${id}`, { status: status }, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (status == 0) {
            Swal.fire('Bien!', 'El Banco ha sido desactivado!', 'success')
          } else {
            Swal.fire('Bien!', 'El Banco ha sido activado!', 'success')
          }
        }),
        catchError(
          (error:any) => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.message);
          }
        ),
      );
  }
}
