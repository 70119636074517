import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Local Modules
//import { PipesModule } from '../pipes/pipes.module';

// Components
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule
  ],
  declarations: [
    SidebarComponent,
    NavbarComponent,
    BreadcrumbsComponent,
    FooterComponent,
    NopagefoundComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SidebarComponent,
    NavbarComponent,
    BreadcrumbsComponent,
    FooterComponent,
    NopagefoundComponent
  ],
  providers: []
})

export class SharedModule {
  constructor() {}
}