import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

// Interfaces 
import { Dae } from '../../../interfaces/interfaces.index';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class DaeService {

  public notificacion = new EventEmitter<any>();

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public getAlls(): Observable<any> {
    return this.http.get<Dae[]>(`${environment.url}api/v1/dae`, { responseType: 'json' });
  }

    public create(model: Dae) {

        model.departure_port_id = Number(model.departure_port_id);
        model.country_id = Number(model.country_id);
        model.transport_type_id = Number(model.transport_type_id);

        return this.http.post<Dae[]>(`${environment.url}api/v1/dae`, model, { observe: 'response' })
          .pipe(
            map((resp: any) => {
                this.notificacion.emit(resp);
                Swal.fire('Bien', 'La Dae ha sido creado exitosamente!', 'success');
            }),
            catchError(error => {
                Swal.fire(`Error ${error.status}`, error.error.message, 'error');
                this.notificacion.emit(error);
                return throwError(error.error.status);
            }),
        );
    }

    public editar(model: Dae) {
      return this.http.put(`${environment.url}api/v1/dae/${model.id}`, model, { observe: 'response' })
        .pipe(
          map((resp: any) => {
            this.notificacion.emit(resp);
            if (resp.body.message.status == 1) {
              Swal.fire('Bien!', 'La Dae ha sido editado, exitosamente', 'success');
            }
            return this.router.navigateByUrl('/dae');
          }),
          catchError(error => this.errorHandlerService.showError(error))
        );
    }

    public activarDesactivar(id: number, status: number) {
      return this.http.put(`${environment.url}api/v1/dae/${id}`, { status: status }, { observe: 'response' })
        .pipe(
          map((resp: any) => {
            this.notificacion.emit(resp);
            if (status == 0) {
              Swal.fire('Bien!', 'La Dae ha sido desactivado!', 'success')
            } else {
              Swal.fire('Bien!', 'La Dae ha sido activado!', 'success')
            }
          }),
          catchError(
            (error:any) => {
              Swal.fire(`Error ${error.status}`, error.error.message, 'error');
              this.notificacion.emit(error);
              return throwError(error.error.message);
            }
          ),
        );
    }


}
