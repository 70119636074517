import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';

// Interfaces 
import { Permission } from '../../../interfaces/interfaces.index';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  public notificacion = new EventEmitter<any>();
  public token: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public getAllPaymentsUser(id:number): Observable<any> {
    return this.http.get(`${environment.url}api/v1/payments/customer/${id}`, { responseType: 'json' });
  }

  public getAllPaymentsUserRango(modelo:any): Observable<any> {
    return this.http.post(`${environment.url}api/v1/payments/customer`, modelo, { responseType: 'json' });
  }

  public getOne(id:number): Observable<any> {
    return this.http.get(`${environment.url}api/v1/payments/` + id, { responseType: 'json' });
  }

  public getOneReport(id:number): Observable<any> {
    return this.http.get(`${environment.url}api/v1/payments/report/` + id, { responseType: 'json' });
  }

  public getAccountState(id:any): Observable<any> {
    return this.http.get(`${environment.url}api/v1/payments/account_state/` + id, { responseType: 'json' });
  }

  public getAccountStateDet(id:any): Observable<any> {
    return this.http.get(`${environment.url}api/v1/payments/account_state_det/` + id, { responseType: 'json' });
  }

  public create(model:any): Observable<any> {
    console.log(model)
    return this.http.post(`${environment.url}api/v1/payments`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          console.log(resp)
          this.notificacion.emit(model.customer);
          Swal.fire('Bien', 'El Pago ha sido registrado exitosamente!', 'success');
        }),
        catchError(error => {
          console.log(error)
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
    );
  }

  public anular(id: number, motive:any, status: number) {

    return this.http.put(`${environment.url}api/v1/payments/cancel/${id}`, 
      { observation: motive, status: status }, 
      { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien!', 'El Pago No. ${id} ha sido anulado exitosamente!', 'success')
        }),
        catchError(
          (error:any) => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.message);
          }
        ),
      );
  }
  
  /*Busqueda de transacciones por ID y No. de Factura*/
  public getGoForIdInvoices(modelo:any): Observable<any> {
    return this.http.post(`${environment.url}api/v1/payments/id_invoices`, modelo, { responseType: 'json' });
  }

  /*Busqueda de transacciones por rango de fecha*/
  public getGoForRango(modelo:any): Observable<any> {
    return this.http.post(`${environment.url}api/v1/payments/tr_rangos`, modelo, { responseType: 'json' });
  }
  
}
