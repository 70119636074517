import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';

import { environment } from 'src/environments/environment';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';

import { Destinatarios } from '../../../interfaces/interfaces.index';

@Injectable({
  providedIn: 'root'
})
export class RecipientsService {

  public destinatarios: Destinatarios;
  public notificacion = new EventEmitter<any>();

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
    ) { }

  public getAll(): Observable<any> {
    return this.http.get<Destinatarios[]>(`${environment.url}api/v1/recipients`, { responseType: 'json' });
  }

  public getOne(id: number) {
      return this.http.get<Destinatarios>(`${environment.url}api/v1/recipients/${id}`, {observe: 'response'})
      .pipe(
          map((resp: any) => {
              return resp.body.message;
          }),
          catchError(
              error => {
                  Swal.fire(`Error ${error.status}`, error.error.message, 'error');
                  this.notificacion.emit(error);
                  return throwError(error.error.status);
              }
          ),
      );
  }

  public crear(model: any) {

    return this.http.post(`${environment.url}api/v1/recipients`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'El Destinatario ha sido creado exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public editar(model: Destinatarios) {
    return this.http.put(`${environment.url}api/v1/recipients/${model.id}`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Editar Destinatario', 'El Destinatario ha sido actualizado, exitosamente', 'success');
          return this.router.navigateByUrl('/recipients');
        }),
        catchError(error => this.errorHandlerService.showError(error))
      );
  }

  public activarDesactivar(id: number, status: number) {
    return this.http.put(`${environment.url}api/v1/recipients/${id}`, { status: status }, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (status == 0) {
            Swal.fire('Bien!', 'El Destinatario ha sido desactivado exitosamente!', 'success')
          } else {
            Swal.fire('Bien!', 'El Destinatario ha sido activado exitosamente!', 'success')
          }
        }),
        catchError(
          error => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.status);
          }
        ),
      );
  }

}
