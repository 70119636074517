import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})

export class PostHarvestService {

  public notificacion = new EventEmitter<any>();
  public notificacionRangoStock = new EventEmitter<any>();

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public getAlls(): Observable<any> {
    return this.http.get<any[]>(`${environment.url}api/v1/post_harvest`, { responseType: 'json' });
  }
  
  public getMesas(): Observable<any> {
    return this.http.post<any[]>(`${environment.url}api/v1/post_harvest/mesas`, {observe: 'response'});
  }

  public getWrapper(): Observable<any> {
    return this.http.post<any[]>(`${environment.url}api/v1/post_harvest/wrapper`, {observe: 'response'});
  }
  public getWarehouse(): Observable<any> {
    return this.http.post<any[]>(`${environment.url}api/v1/post_harvest/warehouse`, {observe: 'response'});
  }

  public getRango(model:any): Observable<any> {
    //return this.http.get<any[]>(`${environment.url}api/v1/post_harvest`, { responseType: 'json' });
    return this.http.post(`${environment.url}api/v1/post_harvest/rango`, model, {observe: 'response'})
      .pipe(
          map((resp: any) => {
            this.notificacionRangoStock.emit(resp); 
            return resp.body;
          }),
          catchError(
            error => {
              Swal.fire(`Error ${error.status}`, error.error.message, 'error');
              this.notificacionRangoStock.emit(error);
              return throwError(error.error.status);
            }
          ),
      );
  }

  public create(model: any) {
    console.log(model)
    return this.http.post<any[]>(`${environment.url}api/v1/post_harvest`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          localStorage.removeItem('selectedData');
          Swal.fire('Bien', 'Datos creados exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

}
