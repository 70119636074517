import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { Usuario } from 'src/app/interfaces/interfaces.index';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';

interface Pass {
    id?: number;
    oldpass: string;
    password: string;
    confirme: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  
  public notificacion = new EventEmitter<any>();
  public usuario: Usuario;
  public token: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
   ) { }

  public loadFromStorage() {
    if(localStorage.getItem('secure') === null) {
      this.usuario = null;
      this.token = '';
    } else {
      this.token = localStorage.getItem('secure');
      this.usuario = JSON.parse(JSON.stringify(jwt_decode(localStorage.getItem('secure')))); 
    }
    return this.usuario;  
  }
  
}
