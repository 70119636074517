import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/services/shared/error-handler.service';
import jwt_decode from 'jwt-decode';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Asignations, Projections } from 'src/app/interfaces/interfaces.index';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AsignationsService {
  public projections: Projections;
  public asignations: Asignations;
  public token: string;
  public usuario: any;
  public notificacion = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { 
    this.loadFromStorage();
  }

  public loadFromStorage() {
    if (localStorage.getItem('secure')) {
      this.token = localStorage.getItem('secure');
      this.usuario = jwt_decode(this.token);
    } else {
      this.token = '';
      this.usuario = null;
    }
  }

  public getAll(): Observable<any> {
    return this.http.get<Asignations[]>(`${environment.url}api/v1/asignations`, { responseType: 'json' });
  }

  public getTotalAmount(): Observable<any> {
    return this.http.get<Projections[]>(`${environment.url}api/v1/projections/ttlamount`, { responseType: 'json' });
  }

  public getBalance(): Observable<any> {
    return this.http.get<Asignations[]>(`${environment.url}api/v1/asignations/balance`, { responseType: 'json' });
  }

  public createAsig(model: Asignations){
 
    return this.http.post<Asignations[]>(`${environment.url}api/v1/asignations`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'La Asignación ha sido creada exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public editar(model: Asignations) {

    model.status = 3;
    return this.http.put(`${environment.url}api/v1/asignations/${model.id}`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Editar Asignación', 'La Asignación ha sido editada, exitosamente', 'success');
        }),
        catchError(error => this.errorHandlerService.showError(error))
      );
  }

  public activarDesactivar(id: number, status: number) {
    return this.http.put(`${environment.url}api/v1/asignations/${id}`, { status: status }, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (status == 0) {
            Swal.fire('Bien!', 'La asignación ha sido desactivada!', 'success')
          } else {
            Swal.fire('Bien!', 'La asignación ha sido activada!', 'success')
          }
        }),
        catchError(
          error => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.status);
          }
        ),
      );
  }

}
