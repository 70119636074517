import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class EbillingService {

  public notificacion = new EventEmitter<any>();

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public getAlls(): Observable<any> {
    return this.http.get(`${environment.url}api/v1/ebilling`, { responseType: 'json' });
  }

  public actualizar(model: any) {
      return this.http.put(`${environment.url}api/v1/ebilling/${model.id}`, model, { observe: 'response' })
        .pipe(
          map((resp: any) => {
            this.notificacion.emit(resp);
            Swal.fire('Bien!', 'La configuración ha sido actualizada, exitosamente', 'success');
            return this.router.navigateByUrl('/ebilling');
          }),
          catchError(error => this.errorHandlerService.showError(error))
        );
    }

}
