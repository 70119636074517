import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class CastService {

  public notificacion = new EventEmitter<any>();

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public sriDocuments(model: any) {
    return this.http.post(`${environment.url}api/v1/sriDocuments`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          //console.log(resp.body.message.sriXml)
          this.notificacion.emit(resp);
          return resp;
          //Swal.fire('Bien', 'Factura enviada a SRI, estado del documento: '+resp.body.message.data, 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public sriAutorize(model: any) {
    return this.http.post(`${environment.url}api/v1/sriDocuments/authorize`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          return resp;
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }
  
  /*
  public editarEstadoxxx(model: any) {
    let state = { sri_status: "NO_PROCESADO"};
    return this.http.put(`${environment.url}api/v1/sriDocuments/sri_state/${model.id}`, state, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien!', 'Estado SRI cambiado exitosamente a NO PROCESADO', 'success');
      }),
        catchError(error => this.errorHandlerService.showError(error))
    );
  }
  */

   public editarEstado(model: any) {
    let state = { sri_status: "NO_PROCESADO"};
    return this.http.put<any>(`${environment.url}api/v1/sriDocuments/sri_state/${model.id}`, state, {observe: 'response'})
        .pipe(
          map((resp: any) => {
            Swal.fire('Bien!', 'Estado SRI cambiado exitosamente a NO PROCESADO', 'success');
            this.notificacion.emit(resp);
            return { state: resp.body.message };
          }),
          catchError(error => this.errorHandlerService.showError(error))
     );
  }

  public getObservations(model: any) {
    return this.http.get(`${environment.url}api/v1/sriDocuments/observations/${model.id}`, {observe: 'response'})
    .pipe(
        map((resp: any) => {
          return resp.body.message;
        }),
        catchError(
            error => {
                Swal.fire(`Error ${error.status}`, error.error.message, 'error');
                this.notificacion.emit(error);
                return throwError(error.error.status);
            }
        ),
    );
  }

  public raeDownload(modelo:any): Observable<any> {
    return this.http.post(`${environment.url}/api/v1/sriDocuments/downloadRae`, modelo, { responseType:'blob'})
  }

}
