import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/services/shared/error-handler.service';
import { throwError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Colors } from 'src/app/interfaces/interfaces.index';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ColorsService {
  public notificacion = new EventEmitter<any>();
  public token: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.loadFromStorage();
  }

  public loadFromStorage() {
    if (localStorage.getItem('secure')) {
      this.token = localStorage.getItem('secure');
    } else {
      this.token = '';
    }
  }

  public getAllColors(): Observable<any> {
    return this.http.get<Colors[]>(`${environment.url}api/v1/colors`, { responseType: 'json' });
  }

  public createColors(model: Colors) {

    return this.http.post<Colors[]>(`${environment.url}api/v1/colors`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'El colors ha sido creado exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public editarColors(model: Colors) {

    return this.http.put(`${environment.url}api/v1/colors/${model.id}`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (resp.body.message.status == 1) {
            Swal.fire('Editar color', 'El color ha sido editado, exitosamente', 'success');
          }
          return this.router.navigateByUrl('/colors');
        }),
        catchError(error => this.errorHandlerService.showError(error))
      );
  }

  public activarDesactivarColors(id: number, status: number) {

    return this.http.put(`${environment.url}api/v1/colors/${id}`, { status: status }, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (status == 0) {
            Swal.fire('Bien!', 'El color ha sido desactivado!', 'success')
          } else {
            Swal.fire('Bien!', 'El color ha sido activado!', 'success')
          }
        }),
        catchError(
          (error:any) => {
            //console.log(error)
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.message);
          }
        ),
        
      );
  }

}
