import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';

import { environment } from 'src/environments/environment';
import { Varieties_dimension } from 'src/app/interfaces/interfaces.index';
import { ErrorHandlerService } from 'src/app/services/shared/error-handler.service';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class VarietiesDimensionService {

  public token: string;
  public _varieties_dimension: Varieties_dimension;
  public usuario: any;
  public notificacion = new EventEmitter<any>();

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { 
  	this.loadFromStorage();
  }

  public loadFromStorage() {
    if (localStorage.getItem('secure')) {
      this.token = localStorage.getItem('secure');
      this.usuario = jwt_decode(this.token);
    } else {
      this.token = '';
      this.usuario = null;
    }
  }

  public getByIdvarietiesDimension(id_varieties:any): Observable<any> {
    return this.http.get<Varieties_dimension[]>(`${environment.url}api/v1/varieties/dimension/${id_varieties}`, { responseType: 'json' });
  }

  public createVarietiesDimensions(model: Varieties_dimension) {

    model.dimension = Number(model.dimension);

    return this.http.post<Varieties_dimension[]>(`${environment.url}api/v1/varieties/dimension`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'La Dimension ha sido creada exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public activarDesactivarVarietiesDimensions(id: number, status: number) {
    return this.http.put(`${environment.url}api/v1/varieties/dimension/${id}`, { status: status }, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (status == 0) {
            Swal.fire('Bien!', 'La dimensión ha sido desactivada!', 'success')
          } else {
            //Swal.fire('Bien!', 'La dimensión ha sido activada!', 'success')
          }
        }),
        catchError(
          error => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.status);
          }
        ),
      );
  }

  public editarVarietiesDimensions(model: Varieties_dimension, name:any) {

    return this.http.put(`${environment.url}api/v1/varieties/dimension/${model.id}`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (resp.body.message.status == 1) {
            Swal.fire('Editar variedad de flor', 'La dimensión de flor ha sido editada, exitosamente', 'success');
          }
          return this.router.navigateByUrl('/varieties-dimension/'+model.varieties_id+'/'+name);
        }),
        catchError(error => this.errorHandlerService.showError(error))
      );
  }



}
