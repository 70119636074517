import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';

//Interfaces
import { environment } from 'src/environments/environment';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  public notificacion = new EventEmitter<any>();

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public fileUpload(archivo:File){
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      let xhr = new XMLHttpRequest();
      formData.append('file', archivo, archivo.name );

      xhr.onreadystatechange = function(){
        if(xhr.readyState === 4){
          if(xhr.status === 200){
            Swal.fire('Exito!','Logo actualizado, debe pulsar el botón actualizar para completar la acción!' ,'success' );
            resolve(xhr.response);
          }else{
            Swal.fire('Error','Ocurrió un error, revise por favorx' ,'error' );
            reject(xhr.response);
          }
        }
      }
      xhr.open('POST', `${environment.url}api/v1/storage`, true);
      xhr.send( formData );
    })
  }

  public fileUploadStorage(archivo:File): Observable<any> {

    const formData: FormData = new FormData()
    formData.append("file", archivo, archivo.name);

    return this.http.post(`${environment.url}api/v1/storage`, formData, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'Archivo actualizado, debe pulsar el botón actualizar para completar la acción!', 'success');
          return resp;
        }),
        catchError(error => {
          console.log(error)
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

}
