import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

// Interfaces 
import { Adicionales } from '../../../interfaces/interfaces.index';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AdditionalsService {

  public notificacion = new EventEmitter<any>();

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private toastr: ToastrService
  ) { }

  public getAlls(): Observable<any> {
    return this.http.get<Adicionales[]>(`${environment.url}api/v1/additionals`, { responseType: 'json' });
  }

  public getOne(id: number) {
      return this.http.get<Adicionales>(`${environment.url}api/v1/additionals/${id}`, {observe: 'response'})
      .pipe(
          map((resp: any) => {
              return resp.body.message;
          }),
          catchError(
              error => {
                  Swal.fire(`Error ${error.status}`, error.error.message, 'error');
                  this.notificacion.emit(error);
                  return throwError(error.error.status);
              }
          ),
      );
  }

  public create(model: Adicionales) {
    return this.http.post<Adicionales[]>(`${environment.url}api/v1/additionals`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
          this.toastr.success('Adicional ha sido creado exitosamente!', 'Bien!');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public remover(id:number): Observable<any> {
    return this.http.delete<Adicionales[]>(`${environment.url}api/v1/additionals/${id}`, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
          this.toastr.success('Elemento removido!', 'Ok!');
        }),
        catchError(
          (error:any) => {
            Swal.fire(`Error ${error.mixta}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.message);
          }
        ),
      );
  }

}
