import { Injectable } from '@angular/core';
import { Usuario } from 'src/app/interfaces/interfaces.index';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  public usuario: Usuario;

  constructor() { }

  public loadFromStorage() {
    if(localStorage.getItem('secure') === null) {
      this.usuario = null;
    } else {
      this.usuario = JSON.parse(JSON.stringify(jwt_decode(localStorage.getItem('secure'))));
    }
    return this.usuario;  
  }

}
