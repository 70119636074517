import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

// Interfaces
import { BankAccount } from '../../../interfaces/interfaces.index';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class BankAccountsService {

	public notificacion = new EventEmitter<any>();

    constructor(
  	    private http: HttpClient,
        private router: Router,
        private errorHandlerService: ErrorHandlerService
    ) { }

    public getAlls(): Observable<any> {
        return this.http.get<BankAccount[]>(`${environment.url}api/v1/bank_account`, { responseType: 'json' });
    }

}
