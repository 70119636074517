import { Injectable } from '@angular/core';
import { Pedidos } from '../../interfaces/interfaces.index';
import * as pedidosActions from '../actions';

const initialState: Pedidos[] = [];

export interface PedidosState {
    pedidos: Pedidos[];
    loaded: boolean;
    loading: boolean;
    error: any;
}

const estadoInicial: PedidosState = {
    pedidos: [],
    loaded: false,
    loading: false,
    error: null
};

export function pedidosReducer(state = estadoInicial, action: pedidosActions.Actions): PedidosState {

    switch (action.type) {
        case pedidosActions.READ_PEDIDO:
            return {
              ...state,
              loading: true
            };

        case pedidosActions.READ_PEDIDO_SUCCESS:
            return {
              ...state,
              loading: false,
              loaded: true,
              pedidos: [...action.payload.pedido],
            };

        case pedidosActions.READ_PEDIDO_FAIL:
            return {
                ...state,
                error: {
                    status: action.payload,
                    message: action.payload,
                }
            };
            
        case pedidosActions.UPDATE_STATE_PEDIDO:
            return {
                ...state,
              loading: true
            };

        case pedidosActions.UPDATE_STATE_PEDIDO_SUCCESS:
            return {
              ...state,
              loading: false,
              loaded: true,
            };

        case pedidosActions.UPDATE_STATE_PEDIDO_FAIL:
            return {
                ...state,
                error: {
                    status: action.payload,
                    message: action.payload,
                }
            };

        case pedidosActions.FILTER_PEDIDOS:
            return {
                ...state,
              loading: true
            };

        case pedidosActions.FILTER_PEDIDOS_SUCCESS:
            return {
              ...state,
              loading: false,
              loaded: true,
              pedidos: [...action.payload.pedido],
            };

        case pedidosActions.FILTER_PEDIDOS_FAIL:
            return {
                ...state,
                error: {
                    status: action.payload,
                    message: action.payload,
                }
            };

        case pedidosActions.FILTER_ORDERS_SALES:
            return {
                ...state,
              loading: true
            };

        case pedidosActions.FILTER_ORDERS_SALES_SUCCESS:
            return {
              ...state,
              loading: false,
              loaded: true,
              pedidos: [...action.payload.pedido],
            };

        case pedidosActions.FILTER_ORDERS_SALES_FAIL:
            return {
                ...state,
                error: {
                    status: action.payload,
                    message: action.payload,
                }
            };

        case pedidosActions.FILTER_ORDERS_SALES_RANGE:
            return {
                ...state,
              loading: true
            };

        case pedidosActions.FILTER_ORDERS_SALES_RANGE_SUCCESS:
            return {
              ...state,
              loading: false,
              loaded: true,
              pedidos: [...action.payload.pedido],
            };

        case pedidosActions.FILTER_ORDERS_SALES_RANGE_FAIL:
            return {
                ...state,
                error: {
                    status: action.payload,
                    message: action.payload,
                }
            };

        default:
            return state;
    }
}