import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Pedidos } from '../../interfaces/interfaces.index';

export const READ_PEDIDO = '[PEDIDO] Read';
export const READ_PEDIDO_SUCCESS = '[PEDIDO] Read Success';
export const READ_PEDIDO_FAIL = '[PEDIDO] Read Fail';

export const UPDATE_STATE_PEDIDO = '[PEDIDO] Update State';
export const UPDATE_STATE_PEDIDO_SUCCESS = '[PEDIDO] Update State Success';
export const UPDATE_STATE_PEDIDO_FAIL = '[PEDIDO] Update State Fail';

export const FILTER_PEDIDOS = '[PEDIDO] Filter';
export const FILTER_PEDIDOS_SUCCESS = '[PEDIDO] Filter Success';
export const FILTER_PEDIDOS_FAIL = '[PEDIDO] Filter Fail';

//Filtrado de ordenes de ventas
export const FILTER_ORDERS_SALES = '[PEDIDO] Filter Orders Sales';
export const FILTER_ORDERS_SALES_SUCCESS = '[PEDIDO] Filter Orders Sales Success';
export const FILTER_ORDERS_SALES_FAIL = '[PEDIDO] Filter Orders Sales Fail';

//Filtrado de ordenes de ventas por rabgo
export const FILTER_ORDERS_SALES_RANGE = '[PEDIDO] Filter Orders Sales Range';
export const FILTER_ORDERS_SALES_RANGE_SUCCESS = '[PEDIDO] Filter Orders Sales Range Success';
export const FILTER_ORDERS_SALES_RANGE_FAIL = '[PEDIDO] Filter Orders Sales Range Fail';

export class ReadPedido implements Action {
    readonly type = READ_PEDIDO;
    constructor(public payload?: any) { }
}

export class ReadPedidoSuccess implements Action {
    readonly type = READ_PEDIDO_SUCCESS;

    constructor(public payload?: any) { }
}

export class ReadPedidoFail implements Action {
    readonly type = READ_PEDIDO_FAIL;

    constructor(public payload?: any) { }
}

export class UpdateStatePedido implements Action {
	readonly type = UPDATE_STATE_PEDIDO;

	constructor(public payload: any){}
}

export class EditStatePedidoSuccess implements Action {
	readonly type = UPDATE_STATE_PEDIDO_SUCCESS;

	constructor(public payload: any) { }
}

export class EditStatePedidoFail implements Action {
	readonly type = UPDATE_STATE_PEDIDO_FAIL;

	constructor(public payload: any) { }
}

export class FilterPedido implements Action {
    readonly type = FILTER_PEDIDOS;
    constructor(public payload?: any) { }
}

export class FilterPedidoSuccess implements Action {
    readonly type = FILTER_PEDIDOS_SUCCESS;
    constructor(public payload?: any) { }
}

export class FilterPedidoFail implements Action {
    readonly type = FILTER_PEDIDOS_FAIL;
    constructor(public payload?: any) { }
}

/*filtro ordenes ventas*/
export class FilterOrdersSales implements Action {
    readonly type = FILTER_ORDERS_SALES;
    constructor(public payload?: any) { }
}
export class FilterOrdersSalesSuccess implements Action {
    readonly type = FILTER_ORDERS_SALES_SUCCESS;
    constructor(public payload?: any) { }
}
export class FilterOrdersSalesFail implements Action {
    readonly type = FILTER_ORDERS_SALES_FAIL;
    constructor(public payload?: any) { }
}

/*filtro ordenes ventas rango*/
export class FilterOrdersSalesRange implements Action {
    readonly type = FILTER_ORDERS_SALES_RANGE;
    constructor(public payload?: any) { }
}
export class FilterOrdersSalesRangeSuccess implements Action {
    readonly type = FILTER_ORDERS_SALES_RANGE_SUCCESS;
    constructor(public payload?: any) { }
}
export class FilterOrdersSalesRangeFail implements Action {
    readonly type = FILTER_ORDERS_SALES_RANGE_FAIL;
    constructor(public payload?: any) { }
}


export type Actions = ReadPedido | 
                      ReadPedidoSuccess | 
                      ReadPedidoFail |
                      UpdateStatePedido | 
                      EditStatePedidoSuccess | 
                      EditStatePedidoFail | 
                      FilterPedido |
                      FilterPedidoSuccess |
                      FilterPedidoFail |
                      FilterOrdersSales |
                      FilterOrdersSalesSuccess |
                      FilterOrdersSalesFail |
                      FilterOrdersSalesRange |
                      FilterOrdersSalesRangeSuccess |
                      FilterOrdersSalesRangeFail;