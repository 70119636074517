import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

// Interfaces 
import { Notascd } from '../../../interfaces/interfaces.index';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class CreditDebitService {

	public notificacion = new EventEmitter<any>();

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public getAlls(): Observable<any> {
    return this.http.get<Notascd[]>(`${environment.url}api/v1/ncd`, { responseType: 'json' });
  }

  public getNotesByUser(id:number): Observable<any> {
    return this.http.get<Notascd[]>(`${environment.url}api/v1/ncd/customer/${id}`, { responseType: 'json' });
  }

  public create(model:any): Observable<any> {

    return this.http.post<Notascd[]>(`${environment.url}api/v1/ncd`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp.body.message.id);
          Swal.fire('Bien', 'El Documento ha sido creado exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
    );
  }

  public anular(id: number, status: number) {
    return this.http.put(`${environment.url}api/v1/ncd/${id}`, { status: status }, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(id);
          if (status == 0) {
            Swal.fire('Bien!', 'El Documento ha sido Anulado!', 'success')
          }
        }),
        catchError(
          (error:any) => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.message);
          }
        ),
      );
  }

}
