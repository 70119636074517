import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})

export class ParametersService {

  public notificacion = new EventEmitter<any>();
  public notificacionRangoStock = new EventEmitter<any>();
  private _token: string;
  private _header: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private usersService: UsersService
  ) { 
    this._token = this.usersService.token;
    this._header = new HttpHeaders({
      'Authorization': 'Bearer ' + this._token,
      'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
    });

  }

  public getAlls(): Observable<any> {
    return this.http.get<any[]>(`${environment.url}api/v1/parameters`, { responseType: 'json', headers: this._header});
  }
  public getCategory(category: string): Observable<any> {
    return this.http.get<any[]>(`${environment.url}api/v1/parameters_category/${category}`, { responseType: 'json', headers: this._header});
  }

  public update(model: any): Observable<any> {
    return this.http.get<any[]>(`${environment.url}api/v1/parameters${model.id}`, { responseType: 'json', headers: this._header});
  }

  public create(model: any) {
    return this.http.post<any[]>(`${environment.url}api/v1/parameters`, model, { observe: 'response', headers: this._header})
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          localStorage.removeItem('selectedData');
          Swal.fire('Bien', 'Datos creados exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

}
