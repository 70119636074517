import { NgModule } from '@angular/core';
// Services
import {
  NavbarService,
  UsersService,
  PermisionService,
  TypesflowersService,
  ColorsService,
  VarietiesService,
  ProjectionsService,
  ProjectionsDetailsService,
  AsignationsService,
  VarietiesDimensionService,
  ProfileService,
  GeneralService,
  UploadService,
  CustomersService,
  SalesService,
  AirlinesService,
  FreightAgenciesService,
  RecipientsService,
  CoolroomsService,
  OrdersService,
  SuppliersService,
  StemsService,
  BunchService,
  BanksService,
  CitiesService,
  CountriesService,
  AdditionalsService,
  InvoicesService,
  CreditDebitService,
  PaymentsService,
  BankAccountsService,
  HomeService,
  ReportService,
  PackingService,
  DaeService,
  EbillingService,
  CastService,
  PostHarvestService,
  WarehousesService,
  PurchaseInvoiceService,  
  PurchaseDetailService,
  BatchService,
  BatchSowingService,
  BlocksService,
  HarvestService,
  SowingService,
  SeasonService
} from './service.index';
@NgModule({
  providers: [
    NavbarService,
    UsersService,
    PermisionService,
    TypesflowersService,
    ColorsService,
    VarietiesService,
    ProjectionsService,
    ProjectionsDetailsService,
    AsignationsService,
    VarietiesDimensionService,
    ProfileService,
    GeneralService,
    UploadService,
    CustomersService,
    SalesService,
    AirlinesService,
    FreightAgenciesService,
    RecipientsService,
    CoolroomsService,
    OrdersService,
    SuppliersService,
    StemsService,
    BunchService,
    BanksService,
    CitiesService,
    CountriesService,
    AdditionalsService,
    InvoicesService,
    CreditDebitService,
    PaymentsService,
    BankAccountsService,
    HomeService,
    ReportService,
    PackingService,
    DaeService,
    EbillingService,
    PostHarvestService,
    WarehousesService,
    PurchaseInvoiceService,
    PurchaseDetailService,
    PaymentsService,
    BatchService,
    BatchSowingService,
    BlocksService,
    HarvestService,
    SowingService,
    SeasonService
  ]
})
export class ServiceModule { }
