import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';

import { environment } from 'src/environments/environment';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  
  public notificacion = new EventEmitter<any>();

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public reportSales(model: any) {
    return this.http.post(`${environment.url}api/v1/orders/report`, model, { responseType: 'blob'})
  }

  public reportVarieties(model: any) {
    return this.http.post(`${environment.url}api/v1/orders/report/varieties`, model, { responseType: 'blob'})
  }

  public reportFlores(model: any) {
    return this.http.post(`${environment.url}api/v1/orders/report/flowers`, model, { responseType: 'blob'})
  }

}
