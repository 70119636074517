import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';

//Interfaces
import { General } from 'src/app/interfaces/interfaces.index';
import { environment } from 'src/environments/environment';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
	public general: General;
	public notificacion = new EventEmitter<any>();
  public token: string;

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.loadFromStorage();
  }

  public loadFromStorage() {
    if (localStorage.getItem('secure')) {
      this.token = localStorage.getItem('secure');
    } else {
      this.token = '';
    }
  }

  public getGeneral(): Observable<any> {
    return this.http.get(`${environment.url}api/v1/general`, { responseType: 'json' });
  }

  public actualizaBussiness(model: General){
    console.log(model)
    return this.http.put<General[]>(`${environment.url}api/v1/general/${model.id}`, model, {observe: 'response'})
    .pipe(
      map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Exito!', 'Los datos de la empresa han sido actualizados, exitosamente', 'success');
          return this.router.navigateByUrl('/general');
        }),
        catchError(error => this.errorHandlerService.showError(error))
    );
  }

  public actualizarEbilling(model: any) {
      return this.http.put(`${environment.url}api/v1/general/ebilling/${model.id}`, model, { observe: 'response' })
        .pipe(
          map((resp: any) => {
            this.notificacion.emit(resp);
            Swal.fire('Bien!', 'La configuración ha sido actualizada, exitosamente', 'success');
            return this.router.navigateByUrl('/ebilling');
          }),
          catchError(error => this.errorHandlerService.showError(error))
        );
    }

}
