import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor() { }

  public showError(error: HttpErrorResponse) {
    Swal.fire(`Error ${error.status}`, error.error.message, 'error');
    return throwError(error || 'Error del servidor, intente más tarde');
  }

  public showErrorLogin(error: HttpErrorResponse) {
    Swal.fire({
    	icon: 'error',
        title: `${error.status}`,
        text: error.error.message,
        showCancelButton: false,
        confirmButtonColor: '#17a2b8',
        confirmButtonText: `Aceptar`,
    }).then((result) => {
      if (result.isConfirmed) {
      	window.location.reload();
      } else if (result.isDenied) {}
    })
    return throwError(error || 'Error del servidor, intente más tarde');
  }

  public showErrorAll(error: HttpErrorResponse) {
    Swal.fire({
      icon: 'error',
        title: `${error.status}`,
        text: error.message,
        showCancelButton: false,
        confirmButtonColor: '#17a2b8',
        confirmButtonText: `Aceptar`,
    }).then((result) => {
      if (result.isConfirmed) {
        //window.location.reload();
      } else if (result.isDenied) {}
    })
    return throwError(error || 'Error de servidor, intente más tarde');
  }

}
