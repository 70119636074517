import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';

import { Projections } from 'src/app/interfaces/interfaces.index';
import { ErrorHandlerService } from 'src/app/services/shared/error-handler.service';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ProjectionsDetailsService {
  public notificacion = new EventEmitter<any>();
  public token: string;
  public projections: Projections;
  public usuario: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { 
    this.loadFromStorage();
  }

  public loadFromStorage() {
    if (localStorage.getItem('secure')) {
      this.token = localStorage.getItem('secure');
      this.usuario = jwt_decode(this.token);
    } else {
      this.token = '';
      this.usuario = null;
    }
  }

  public getByIdFlower(idflower:any): Observable<any> {
    let datos = idflower +','+ this.usuario.sub;
    return this.http.get<Projections[]>(`${environment.url}api/v1/projections/graphica/${datos}`, { responseType: 'json' });
  }

  public editarPro(model: Projections) {
    return this.http.put(`${environment.url}api/v1/projections/${model.id}`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Editar Proyección', 'La Proyección ha sido editada, exitosamente', 'success');
        }),
        catchError(error => this.errorHandlerService.showError(error))
      );
  }

  public goYearsProjections(year:any, flower:any): Observable<any> {
    let datos = year +','+ this.usuario.sub +','+ flower;
    return this.http.get<Projections[]>(`${environment.url}api/v1/projections/years/${datos}`, { responseType: 'json' });
  }

}