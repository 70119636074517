import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

// Interfaces 
import { Proveedores } from '../../../interfaces/interfaces.index';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SuppliersService {

  public notificacion = new EventEmitter<any>();

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private toastr: ToastrService
  ) { }

  public getAlls(): Observable<any> {
    return this.http.get<Proveedores[]>(`${environment.url}api/v1/suppliers`, { responseType: 'json' });
  }

  public getOne(id: number) {
      return this.http.get<Proveedores>(`${environment.url}api/v1/suppliers/${id}`, {observe: 'response'})
      .pipe(
        map((resp: any) => {
          return resp.body.message;
        }),
        catchError(
          error => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.status);
          }
        ),
      );
  }

  public create (model: Proveedores) {
    model.payment_days = Number(model.payment_days);
    model.accounting = Number(model.accounting);
    model.qualification = Number(model.qualification);
    model.compliance = Number(model.compliance);
    model.status = Number(model.status);
    return this.http.post<Proveedores[]>(`${environment.url}api/v1/suppliers`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'El Proveedor ha sido creado exitosamente!', 'success');
          return this.router.navigateByUrl('/suppliers');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public editar(model: Proveedores) {
    model.payment_days = Number(model.payment_days);
    model.accounting = Number(model.accounting);
    model.qualification = Number(model.qualification);
    model.compliance = Number(model.compliance);
    model.status = Number(model.status);
    return this.http.put(`${environment.url}api/v1/suppliers/${model.id}`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Editar Proveedor', 'El Proveedor ha sido editado, exitosamente', 'success');
          return this.router.navigateByUrl('/suppliers');
        }),
        catchError(error => this.errorHandlerService.showError(error))
      );
  }

  public activarDesactivar(id: number, status: number) {
      return this.http.put(`${environment.url}api/v1/suppliers/${id}`, {status: status}, {observe: 'response'})
          .pipe(
              map((resp: any) => {
                  this.notificacion.emit(resp);
                  if(status == 0){
                      Swal.fire('Bien!', 'El proveedor ha sido desactivado!', 'success')
                  }else{
                      Swal.fire('Bien!', 'El proveedor ha sido activado!', 'success')
                  }
              }),
              catchError(
                  error => {
                      Swal.fire(`Error ${error.status}`, error.error.message, 'error');
                      this.notificacion.emit(error);
                      return throwError(error.error.status);
                  }
              ),
          );
  }

}
