<nav class="navbar topbar">
    <div class="top-left-menu">
        <p><b>Conexión:</b> {{ formateFecha(usuario[0].last_conexion) }}</p>
    </div>
    <ul class="list-unstyled top-right-menu">
        <!-- 
        <li class="nav-item topbar-dropdown">
            <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false"
                aria-expanded="false" id="emailsDropdown">
                <i class="fa fa-envelope"></i>
                <span class="badge bg-success rounded-circle">6</span>
            </a>
            <div class="dropdown-menu dropdown-animate dropdown-menu-lg dropdown-menu-right py-0"
                aria-labelledby="emailsDropdown">
                <div class="dropdown-menu-header">
                    6 New Emails
                </div>
                <div class="list-group">
                    <a href="#" class="list-group-item">
                        <div class="row no-gutters align-items-center">
                            <div class="col-2">
                                <img src="assets/images/avatar.png" class="user-avatar rounded-circle" alt="User">
                            </div>
                            <div class="col-10">
                                <div>Sam Robert</div>
                                <div class="text-muted small mt-1">Ability to select/create Modules</div>
                                <div class="text-muted small mt-1">1h ago</div>
                            </div>
                        </div>
                    </a>
                    <a href="#" class="list-group-item">
                        <div class="row no-gutters align-items-center">
                            <div class="col-2">
                                <img src="assets/images/default-female-avatar.jpg" alt="User"
                                    class="user-avatar rounded-circle">
                            </div>
                            <div class="col-10">
                                <div>Schirsten Vander</div>
                                <div class="text-muted small mt-1">Add widgets descriptions</div>
                                <div class="text-muted small mt-1">2h ago</div>
                            </div>
                        </div>
                    </a>
                    <a href="#" class="list-group-item">
                        <div class="row no-gutters align-items-center">
                            <div class="col-2">
                                <img src="assets/images/default-female-avatar.jpg" alt="User"
                                    class="user-avatar rounded-circle">
                            </div>
                            <div class="col-10">
                                <div>Robert Nordic</div>
                                <div class="text-muted small mt-1">Mark cancelled the event</div>
                                <div class="text-muted small mt-1">4h ago</div>
                            </div>
                        </div>
                    </a>
                    <a href="#" class="list-group-item">
                        <div class="row no-gutters align-items-center">
                            <div class="col-2">
                                <img src="assets/images/default-female-avatar.jpg" alt="User"
                                    class="user-avatar rounded-circle">
                            </div>
                            <div class="col-10">
                                <div>Michael Branson</div>
                                <div class="text-muted small mt-1">Lorem ipsum dolor sit amet</div>
                                <div class="text-muted small mt-1">6h ago</div>
                            </div>
                        </div>
                    </a>
                    <a href="#" class="list-group-item">
                        <div class="row no-gutters align-items-center">
                            <div class="col-2">
                                <img src="assets/images/default-female-avatar.jpg" alt="User"
                                    class="user-avatar rounded-circle">
                            </div>
                            <div class="col-10">
                                <div>Antonym Sladers</div>
                                <div class="text-muted small mt-1">Lorem ipsum dolor sit amet</div>
                                <div class="text-muted small mt-1">6h ago</div>
                            </div>
                        </div>
                    </a>
                    <a href="#" class="list-group-item">
                        <div class="row no-gutters align-items-center">
                            <div class="col-2">
                                <img src="assets/images/default-female-avatar.jpg" alt="User"
                                    class="user-avatar rounded-circle">
                            </div>
                            <div class="col-10">
                                <div>Marcus</div>
                                <div class="text-muted small mt-1">Lorem ipsum dolor sit amet</div>
                                <div class="text-muted small mt-1">6h ago</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="dropdown-menu-footer">
                    <a href="#" class="text-primary">View all</a>
                </div>
            </div>
        </li>
        -->
        <!-- 
        <li class="nav-item notification-list topbar-dropdown">
            <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false"
                aria-expanded="false" id="notificationsDropdown">
                <i class="fa fa-bell"></i>
                <span class="badge bg-info rounded-circle">4</span>
            </a>
            
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0 dropdown-animate"
                aria-labelledby="notificationsDropdown">
                <div class="dropdown-menu-header">
                    4 New Notifications
                </div>
                <div class="list-group">
                    <a href="#" class="list-group-item">
                        <div class="row align-items-center">
                            <div class="col-2">
                                <i class="ms-1 text-primary fas fa-fw fa-user"></i>
                            </div>
                            <div class="col-10">
                                <div>New user registered</div>
                                <div class="text-muted small mt-1">Lorem ipsum dolor sit amet</div>
                                <div class="text-muted small mt-1">1h ago</div>
                            </div>
                        </div>
                    </a>
                    <a href="#" class="list-group-item">
                        <div class="row align-items-center">
                            <div class="col-2">
                                <i class="ms-1 text-warning fas fa-fw fa-bell"></i>
                            </div>
                            <div class="col-10">
                                <div>Update completed</div>
                                <div class="text-muted small mt-1">Lorem ipsum dolor sit amet.</div>
                                <div class="text-muted small mt-1">2h ago</div>
                            </div>
                        </div>
                    </a>
                    <a href="#" class="list-group-item">
                        <div class="row align-items-center">
                            <div class="col-2">
                                <i class="ms-1 text-info fas fa-fw fa-envelope-open"></i>
                            </div>
                            <div class="col-10">
                                <div>Mark cancelled the event</div>
                                <div class="text-muted small mt-1">Lorem ipsum dolor sit amet, consectetur adip</div>
                                <div class="text-muted small mt-1">4h ago</div>
                            </div>
                        </div>
                    </a>
                    <a href="#" class="list-group-item">
                        <div class="row align-items-center">
                            <div class="col-2">
                                <i class="ms-1 text-danger fas fa-fw fa-building"></i>
                            </div>
                            <div class="col-10">
                                <div>Login from 192.168.1.1</div>
                                <div class="text-muted small mt-1">6h ago</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="dropdown-menu-footer">
                    <a href="#" class="text-primary">View all</a>
                </div>
            </div>
        
        </li>
        -->
        <li class="nav-item user-setting-list topbar-dropdown" *ngFor="let usuarios of usuario">
            
            <a class="nav-link dropdown-toggle nav-profile" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                
                <img src="assets/images/avatar.png" alt="user-image" class="rounded-circle">
                <span class="ms-1 d-none d-lg-inline">
                    <span class="user-name">Hola, {{ usuarios.name }}</span>
                </span>
            </a>

            <div class="dropdown-menu dropdown-menu-right profile-dropdown dropdown-animate">
                <div class="dropdown-header">
                    <h6 class="text-center">Bienvenido !</h6>
                </div>
                <a routerLink="/profile" class="dropdown-item">
                    <i class="fa fa-user me-1"></i>
                    <span>Perfil</span>
                </a>
                <a (click)="usersService.logout()" class="dropdown-item pointer">
                    <i class="fa fa-sign-out me-1"></i>
                    <span>Salir</span>
                </a>
            </div>
        </li>
        <li class="nav-item">
            <a href="#right-sidebar" class="nav-link" data-bs-toggle="modal" id="modalSetting">
                <i class="fa fa-cog rotate"></i>
            </a>
        </li>
    </ul>
</nav>