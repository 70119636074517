import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core'; 
import { Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/services/shared/error-handler.service';
import { throwError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Typesflowers } from 'src/app/interfaces/interfaces.index';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class TypesflowersService {
  public notificacion = new EventEmitter<any>();
  public token: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.loadFromStorage();
  }

  public loadFromStorage() {
    if (localStorage.getItem('secure')) {
      this.token = localStorage.getItem('secure');
    } else {
      this.token = '';
    }
  }

  public getAllTypesflowers(): Observable<any> {
    return this.http.get<Typesflowers[]>(`${environment.url}api/v1/typesflowers`, { responseType: 'json' });
  }

  public createFlowers(model: Typesflowers) {

    return this.http.post<Typesflowers[]>(`${environment.url}api/v1/typesflowers`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'El tipo de flor ha sido creado exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public editarFlor(model: Typesflowers) {

    return this.http.put(`${environment.url}api/v1/typesflowers/${model.id}`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (resp.body.message.status == 1) {
            Swal.fire('Editar tipo de flor', 'El tipo de flor ha sido editado, exitosamente', 'success');
          }
          return this.router.navigateByUrl('/typesflowers');
        }),
        catchError(error => this.errorHandlerService.showError(error))
      );
  }

  public activarDesactivarFlor(id: number, status: number) {
    return this.http.put(`${environment.url}api/v1/typesflowers/${id}`, { status: status }, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (status == 0) {
            Swal.fire('Bien!', 'El Tipo de flor ha sido desactivado!', 'success')
          } else {
            Swal.fire('Bien!', 'El Tipo de flor ha sido activado!', 'success')
          }
        }),
        catchError(
          error => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.status);
          }
        ),
      );
  }

}
