import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

// Services
import { ErrorHandlerService } from '../shared/error-handler.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  public notificacion = new EventEmitter<any>();
  public loading: boolean = false;

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private toastr: ToastrService
  ) { }

  public getOne(id:number): Observable<any> {
    return this.http.get(`${environment.url}api/v1/invoices/` + id, { responseType: 'json' });
  }

  public getCommercial(id:number): Observable<any> {
    return this.http.get(`${environment.url}api/v1/invoices/commercial/` + id, { responseType: 'json' });
  }

  public getAllInvoicesUser(id:number): Observable<any> {
    return this.http.get(`${environment.url}api/v1/invoices/user/` + id, { responseType: 'json' });
  }
  
  /*descarga factura comercial*/
  public downloadPdfInvoice(modelo:any): Observable<any> {
    return this.http.post(`${environment.url}/api/v1/invoices/downloadCom`, modelo, { responseType:'blob'})
  }

  public getAllInvoicesRangoByCustomer(model:any) {
      return this.http.post(`${environment.url}api/v1/invoices/rangocustom`, model, {observe: 'response'})
      .pipe(
          map((resp: any) => {
            return resp.body.message;
          }),
          catchError(
            error => {
              Swal.fire(`Error ${error.status}`, error.error.message, 'error');
              return throwError(error.error.status);
            }
          ),
      );
  }

  public sendMails(model: any) {

    return this.http.post(`${environment.url}api/v1/send`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
          this.toastr.success('Factura enviada a correo de cliente!', 'Bien!');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          //window.location.reload();
          return throwError(error.error.status);
        }),
      );
  }

  public sendMailsAccountState(model: any) {
    return this.http.post(`${environment.url}api/v1/send`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
          this.toastr.success('Estado de Cuenta enviado a correo del cliente!', 'Bien!');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

}
