import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';

// Interfaces 
import { Permission, Permission_role } from '../../../interfaces/interfaces.index';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class PermisionService {
  public notificacion = new EventEmitter<any>();
  public token: string;
  public permission_role: Permission_role;

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.loadFromStorage();
  }

  public loadFromStorage() {
    if (localStorage.getItem('secure')) {
      this.token = localStorage.getItem('secure');
    } else {
      this.token = '';
      //this.rol = '';
    }
  }

  public getAlls(): Observable<any> {
    return this.http.get<Permission[]>(`${environment.url}api/v1/permission`, { responseType: 'json' });
  }

  public getAllPermisions(): Observable<any> {
    return this.http.get<Permission_role[]>(`${environment.url}api/v1/permission/role`, { responseType: 'json' });
  }

  public createPermissionRole(model: Permission_role) {

    model.permission_id = Number(model.permission_id);
    model.role_id = Number(model.role_id);

    return this.http.post<Permission_role[]>(`${environment.url}api/v1/permission`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'El permiso ha sido creado exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public activarDesactivarPermiso(idPermiso: number, status: number) {
    return this.http.put(`${environment.url}api/v1/permission/${idPermiso}`, { status: status }, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (status == 0) {
            Swal.fire('Bien!', 'El permiso ha sido desactivado exitosamente!', 'success')
          } else {
            Swal.fire('Bien!', 'El permiso ha sido activado exitosamente!', 'success')
          }
        }),
        catchError(
          error => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.status);
          }
        ),
      );
  }

}
