import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

import { dataChart } from 'src/app/interfaces/interfaces.index';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

    constructor(
    	private http: HttpClient,
        private router: Router,
        private errorHandlerService: ErrorHandlerService,
        private toastr: ToastrService
    ) { }

    public getAlls(): Observable<any> {
        return this.http.get<any[]>(`${environment.url}api/v1/home`, { responseType: 'json' });
    }

    public getAllPagos(): Observable<any> {
        return this.http.get<any[]>(`${environment.url}api/v1/home/pay`, { responseType: 'json' });
    }

}
