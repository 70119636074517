<div class="container" style="max-width: 100%; background-image: url('assets/images/bg.jpg');">
    <div class="row justify-content-center align-items-center vh-100" *ngIf="!loading">
        <div class="col-xl-4 col-lg-5 px-4">
            <div class="card shadow-sm rounded-5">
                <div class="card-body p-4">
                    <div class="text-center mb-4">
                        <a href="/">
                            <img src="assets/images/logo_megaflor_b.png" style="width: 280px;" class="img-fluid" alt="Logo">
                        </a>
                    </div>
                    <div class="text-center m-auto">
                        <p class="text-muted mb-4">Por favor introduzca su correo y contraseña</p>
                    </div>

                    <form (ngSubmit)="login()" [formGroup]="loginForm" class="form-horizontal form-material" id="loginform" novalidate>

                        <div class="input-group"
                            [ngClass]="{'text-danger animated shake': loginForm.controls.email.errors && loginForm.controls.email.touched}">
                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope"></i></span>
                            <input formControlName="email" class="form-control" type="text" placeholder="Usuario">
                        </div>

                        <small class="text-danger"
                                *ngIf="loginForm.controls.email.errors?.required && loginForm.controls.email.touched">
                                * El usuario es obligatorio</small>
                        <small class="text-danger"
                                *ngIf="loginForm.controls.email.errors?.email && loginForm.controls.email.touched">* Esta
                                no es una dirección válida de email</small>

                        <div class="input-group mt-4"
                            [ngClass]="{'text-danger animated shake': loginForm.controls.password.errors && loginForm.controls.password.touched}">
                            <span class="input-group-text" id="basic-addon2"><i class="fa fa-lock"></i></span>
                            <input formControlName="password" class="form-control" type="password" required="" placeholder="Contraseña"/>
                        </div>
                        <small class="text-danger"
                            *ngIf="loginForm.controls.password.errors?.required && loginForm.controls.password.touched">
                            * La contraseña es obligatoria</small>
                        
                        <div class="mb-3 mt-4 justify-content-between d-flex">
                            <label class="custom-checkbox">
                                <input type="checkbox" id="remember-me"> Recuerdame
                                <span></span>
                            </label>
                            <a href="pages-authentication-forgot-password.html" class="text-info ms-1">Olvidó su
                                contraseña?</a>
                        </div>

                        <div class="d-grid mb-0 text-center">
                            <button [disabled]="loginForm.invalid" class="btn btn-info d-block w-100 text-white"
                                type="submit"> <span>Entrar</span></button>
                        </div>
                        <p class="text-center my-4"></p>

                    </form>

                </div>
            </div>

        </div>
    </div>
    
    <div class="loader" *ngIf="loading">
        <div class="spinner-border text-danger m-2" role="status"></div>
        <p class="loader__label">Procesando</p>
    </div>
    
</div>