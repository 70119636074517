import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { UsersService } from '../users/users.service';
  
// Interfaces 
import { Warehouses } from '../../../interfaces/interfaces.index';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class WarehousesService {

  private _token: string;
  private _header: any;
  public notificacion = new EventEmitter<any>();

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private usersService: UsersService
  ) { 
        this._token = this.usersService.token;
        this._header = new HttpHeaders({
          'Authorization': 'Bearer ' + this._token,
          'Cache-Control': 'no-cache',
            Pragma: 'no-cache'
        });
  }

  public getAlls(): Observable<any> {

    return this.http.get<Warehouses[]>(`${environment.url}api/v1/warehouses`, { responseType: 'json', headers: this._header });
  }

  public getOne(id: number) {
    return this.http.get<Warehouses>(`${environment.url}api/v1/warehouses/${id}`, {observe: 'response', headers: this._header})
      .pipe(
        map((resp: any) => {
          return resp.body.message;
        }),
        catchError(
          error => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.status);
          }
        ),
    );
  }

  public create(model: Warehouses) {
    //model.idsri = Number(model.idsri);
    return this.http.post<Warehouses[]>(`${environment.url}api/v1/warehouses`, model, { observe: 'response', headers: this._header})
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'La Bodega ha sido creada exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public editar(model: Warehouses) {
console.log(this._header);
    return this.http.put(`${environment.url}api/v1/warehouses/${model.id}`, model, { observe: 'response', headers: this._header })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (resp.body.message.status == 1) {
            Swal.fire('Editar Bodega', 'La Bodega ha sido editado, exitosamente', 'success');
          }
          return this.router.navigateByUrl('/warehouses');
        }),
        catchError(error => this.errorHandlerService.showError(error))
      );
  }

  public activarDesactivar(id: number, status: number) {
    return this.http.put(`${environment.url}api/v1/warehouses/${id}`, { status: status }, { observe: 'response', headers: this._header })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (status == 0) {
            Swal.fire('Bien!', 'La Bodega ha sido desactivado!', 'success')
          } else {
            Swal.fire('Bien!', 'La Bodega ha sido activado!', 'success')
          }
        }),
        catchError(
          (error:any) => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.message);
          }
        ),
      );
  }
}
