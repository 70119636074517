import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';

import { environment } from 'src/environments/environment';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';

import { Freight } from '../../../interfaces/interfaces.index';

@Injectable({
  providedIn: 'root'
})
export class FreightAgenciesService {

  public freight: Freight;
  public notificacion = new EventEmitter<any>();
  public token: string;

  constructor(
  	private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
    ) { }

  public getAll(): Observable<any> {
    return this.http.get<Freight[]>(`${environment.url}api/v1/freight`, { responseType: 'json' });
  }

  public getOne(id: number) {
      return this.http.get<Freight>(`${environment.url}api/v1/freight/${id}`, {observe: 'response'})
      .pipe(
          map((resp: any) => {
              return resp.body.message;
          }),
          catchError(
              error => {
                  Swal.fire(`Error ${error.status}`, error.error.message, 'error');
                  this.notificacion.emit(error);
                  return throwError(error.error.status);
              }
          ),
      );
  }

  public crear(model: Freight) {

    return this.http.post<Freight[]>(`${environment.url}api/v1/freight`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'La Agencia de Carga ha sido creada exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public editar(model: Freight) {

    return this.http.put(`${environment.url}api/v1/freight/${model.id}`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Editar Agencia de Carga', 'La Agencia de Carga ha sido actualizada, exitosamente', 'success');
          return this.router.navigateByUrl('/freight_agencies');
        }),
        catchError(error => this.errorHandlerService.showError(error))
      );
  }

  public activarDesactivar(id: number, status: number) {
    return this.http.put(`${environment.url}api/v1/freight/${id}`, { status: status }, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (status == 0) {
            Swal.fire('Bien!', 'La Agencia de Carga ha sido desactivada exitosamente!', 'success')
          } else {
            Swal.fire('Bien!', 'La Agencia de Carga ha sido activada exitosamente!', 'success')
          }
        }),
        catchError(
          error => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.status);
          }
        ),
      );
  }

}
