import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

// Interfaces 
import { Usuario, Rol, Category } from '../../../interfaces/interfaces.index';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  public notificacion = new EventEmitter<any>();
  public token: string;
  public role: Rol;
  public usuario: Usuario;
  public menu: Category[];

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.loadFromStorage();
  }

  public loadFromStorage() {
    if (localStorage.getItem('secure')) {
      this.token = localStorage.getItem('secure');
      this.usuario = jwt_decode(this.token);
      this.menu = JSON.parse(JSON.stringify(jwt_decode(localStorage.getItem('access'))));//menu
    } else {
      this.token = '';
      this.usuario = null;
      this.menu = [];
    }
  }

  public getAllRoles(): Observable<any> {
    return this.http.get<Rol[]>(`${environment.url}api/v1/roles`, { responseType: 'json' });
  }

  public getById(id:number): Observable<any> {
    return this.http.get<Rol[]>(`${environment.url}api/v1/roles/{id}`, { responseType: 'json' });
  }

  public createRoles(model: Rol) {
    return this.http.post<Rol[]>(`${environment.url}api/v1/roles`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'El rol ha sido creado exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public editarRole(model: Rol) {
    return this.http.put(`${environment.url}api/v1/roles/${model.id}`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Editar Rol', 'El rol ha sido editado, exitosamente', 'success');
          return this.router.navigateByUrl('/role');
        }),
        catchError(error => this.errorHandlerService.showError(error))
      );
  }

  public activarDesactivarRole(id: number, status: number) {
    return this.http.put(`${environment.url}api/v1/roles/${id}`, { status: status }, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (status == 0) {
            Swal.fire('Bien!', 'El Rol ha sido desactivado!', 'success')
          } else {
            Swal.fire('Bien!', 'El Rol ha sido activado!', 'success')
          }
        }),
        catchError(
          error => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.status);
          }
        ),
      );
  }

}
