import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UsersService } from '../users/users.service';
//interfaces
import { Blocks } from 'src/app/interfaces/interfaces.index';
@Injectable({
  providedIn: 'root'
})

export class BlocksService {

  public notificacion = new EventEmitter<any>();
  public notificacionRangoStock = new EventEmitter<any>();
  private _token: string;
  private _header: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private usersService: UsersService
  ) { 
    this._token = this.usersService.token;
    this._header = new HttpHeaders({
      'Authorization': 'Bearer ' + this._token,
      'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
    });

  }

  public getAlls(): Observable<any> {
    return this.http.get<any[]>(`${environment.url}api/v1/blocks`, { responseType: 'json', headers: this._header});
  }

  public update(model: Blocks): Observable<any> {
    return this.http.put(`${environment.url}api/v1/blocks/${model.id}`, model, { observe: 'response' })
  }

  public create(model: any) {
    return this.http.post<any[]>(`${environment.url}api/v1/blocks`, model, { observe: 'response', headers: this._header})
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          //localStorage.removeItem('selectedData');
          Swal.fire('Bien', 'Bloque creado exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public activarDesactivar(id: number, status: number) {
    return this.http.put(`${environment.url}api/v1/blocks/${id}`, {status: status}, {observe: 'response'})
        .pipe(
            map((resp: any) => {
                this.notificacion.emit(resp);
                if(status == 0){
                    Swal.fire('Bien!', 'El bloque ha sido desactivado, exitosamente!', 'success')
                }else{
                    Swal.fire('Bien!', 'El bloque ha sido activado, exitosamente!', 'success')
                }
            }),
            catchError(
                error => {
                    Swal.fire(`Error ${error.status}`, error.error.message, 'error');
                    this.notificacion.emit(error);
                    return throwError(error.error.status);
                }
            ),
        );
}

}
