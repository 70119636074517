import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/services/shared/error-handler.service';
import { throwError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Tallos } from 'src/app/interfaces/interfaces.index';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class StemsService {
  public notificacion = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public getAlls(): Observable<any> {
    return this.http.get<Tallos[]>(`${environment.url}api/v1/stems`, { responseType: 'json' });
  }

  public create(model: Tallos) {
    model.valor = Number(model.valor);
    return this.http.post<Tallos[]>(`${environment.url}api/v1/stems`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'El Tallo ha sido creado exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public editar(model: Tallos) {

    return this.http.put(`${environment.url}api/v1/stems/${model.id}`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (resp.body.message.status == 1) {
            Swal.fire('Editar tallo', 'El tallo ha sido editado, exitosamente', 'success');
          }
          return this.router.navigateByUrl('/stems');
        }),
        catchError(error => this.errorHandlerService.showError(error))
      );
  }

  public activarDesactivar(id: number, status: number) {

    return this.http.put(`${environment.url}api/v1/stems/${id}`, { status: status }, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (status == 0) {
            Swal.fire('Bien!', 'El tallo ha sido desactivado!', 'success')
          } else {
            Swal.fire('Bien!', 'El tallo ha sido activado!', 'success')
          }
        }),
        catchError(
          (error:any) => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.message);
          }
        ),
        
      );
  }

}
