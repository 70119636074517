import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { Usuario } from 'src/app/interfaces/interfaces.index';
import { NavbarService, UsersService } from 'src/app/services/service.index';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  providers: [DatePipe]
})
export class NavbarComponent implements OnInit {

  public usuario: Usuario[];
  public loading: boolean;

  constructor(
    public navbarService: NavbarService,
    public usersService: UsersService,
    private router: Router,
    private _datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.loading = true;
    this.datos();    
  }

  formateFecha(fecha: string){
    return this._datePipe.transform(fecha,"dd/MM/yyyy, h:mm a");
  }

  datos() {
    this.loading = false;
    let users = this.navbarService.loadFromStorage();
    this.usuario = [{
      name: users.name,
      surname: users.surname,
      email: users.email,
      password: "",
      type_document: "",
      num_document: "",
      address: users.address,
      id_rol: users.id_rol,
      rol: users.rol,
      status: 1,
      phone: users.phone,
      last_conexion: users.last_conexion,
      emission_point: users.emission_point,
    }]
  }
  
}