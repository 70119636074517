
<!-- sidebar -->
<app-sidebar></app-sidebar>

<div class="page-container">

  <!-- navbar -->
  <app-navbar></app-navbar>
 
  <div class="page-content">

    <app-breadcrumbs></app-breadcrumbs>

    <div class="card p-4">
      <router-outlet></router-outlet>
    </div>

  </div>

  <app-footer></app-footer>

</div>
