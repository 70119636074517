<nav id="left-sidebar" class="left-sidebar">

    <!-- start sidebar-header #app-shrinking-left-sidebar -->
    <div class="sidebar-header">
      <div class="logo" *ngIf="!visible">
        <a href="#">
          <img src="assets/images/logo_megaflor_b.png" alt="homepage" class="logo" width="190px" />
        </a>
      </div>
      <a class="sidebar-toggle-btn" (click)="clickToggle();">
        <i class="fa fa-bars"></i>
      </a>
    </div>
    <!-- end sidebar-header -->

    <div class="sidebar-wrapper" id="btn-sidebar-wrapper">
      <!-- start sidebar-body -->
      <div class="sidebar-body">

        <!-- end nav-profile -->
        <!-- start sidebar-block -->
        <div class="sidebar-block">

          <div  class="sidebar-title">MENÚ</div>

          <ul class="list-unstyled sidebar-content">

            <!--inicio-->
            <li class="sidebar-item">
              <a (click)="inicio();" data-bs-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                <i class="fa fa-home"></i>
                <span>Home</span>
              </a>
            </li>
            <!--fin inicio-->
            
            <!--menu-->
            <li class="sidebar-item"  *ngFor="let category of _sidebar.menu">

              <a data-bs-toggle="collapse" class="dropdown-toggle collapsed" href="#{{ category.path }}" aria-expanded="false">
                <i class="{{category.icon}}"></i>
                <span>{{ category.title }}</span>
              </a>

              <ul id="{{ category.path }}" aria-expanded="false" class="sidebar-second-level collapse list-unstyled" data-parent="#left-sidebar">
                <li *ngFor="let subcategory of category.subcategories">
                  <a [routerLink]="[ subcategory.url ]" routerLinkActive="active">{{ subcategory.title }}</a>
                </li>
              </ul>

            </li><!-- fin menu -->

          </ul>

        </div><!-- end sidebar-block -->

      </div>

      <!-- end sidebar-footer -->
    </div><!-- end sidebar-wrapper -->
  </nav>