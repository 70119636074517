<div id="footer" class="d-flex align-items-center">
    <div class="row w-100">
        <div class="footer-nav col-md-8 col-sm-6">
            <a href="#" target="_blank" class="footer-nav-item">Nosotros</a>
            <a href="#" target="_blank" class="footer-nav-item">Suporte</a>
            <a href="#" target="_blank" class="footer-nav-item">Contacto</a>
        </div>
        <div class="copyright text-end col-md-4 col-sm-6">
            <script>document.write(new Date().getFullYear())</script>&nbsp;©&nbsp;<a href="#"
                target="_blank">Ecupyme</a>
        </div>
    </div>
</div>