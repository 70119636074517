import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';

import { environment } from 'src/environments/environment';
import { Varieties, Dimension, Varieties_dimension } from 'src/app/interfaces/interfaces.index';
import { ErrorHandlerService } from 'src/app/services/shared/error-handler.service';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class VarietiesService {

  public token: string;
  public varieties: Varieties;
  public usuario: any;
  public notificacion = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { 
    this.loadFromStorage();
  }

  public loadFromStorage() {
    if (localStorage.getItem('secure')) {
      this.token = localStorage.getItem('secure');
      this.usuario = jwt_decode(this.token);
    } else {
      this.token = '';
      this.usuario = null;
    }
  }
  public getListVarieties(): Observable<any> {
    return this.http.get<Varieties[]>(`${environment.url}api/v1/varieties`, { responseType: 'json' });
  }

  public getAllVarieties(): Observable<any> {
    return this.http.get<Varieties[]>(`${environment.url}api/v1/varieties/florist/${this.usuario.sub}`, { responseType: 'json' });
  }

  public getAllDimensiones(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.get<Dimension[]>(`${environment.url}api/v1/dimension`, { headers, responseType: 'json' });
  }

  public createVarieties(model: Varieties) {

    model.id_colors = Number(model.id_colors);
    model.id_typeflower = Number(model.id_typeflower);
    model.id_florist = this.usuario.sub;

    return this.http.post<Varieties[]>(`${environment.url}api/v1/varieties`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'La Variedad de flor ha sido creada exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public editarVarietie(model: Varieties) {

    return this.http.put(`${environment.url}api/v1/varieties/${model.id}`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (resp.body.message.status == 1) {
            Swal.fire('Editar variedad de flor', 'La variedad de flor ha sido editada, exitosamente', 'success');
          }
          return this.router.navigateByUrl('/varieties');
        }),
        catchError(error => this.errorHandlerService.showError(error))
      );
  }

  public activarDesactivarVarieties(id: number, status: number) {

    return this.http.put(`${environment.url}api/v1/varieties/${id}`, { status: status }, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (status == 0) {
            Swal.fire('Bien!', 'La variedad de flor ha sido desactivada!', 'success')
          } else {
            Swal.fire('Bien!', 'La variedad de flor ha sido activada!', 'success')
          }
        }),
        catchError(
          error => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.status);
          }
        ),
      );
  }

  public goVarieties(search: string): Observable<any> {
    return this.http.get<any>(`${environment.url}api/v1/varieties/search/${search}`, { responseType: 'json' });
  }
  
  /*Dimensiones*/
  public activarDesactivarDimension(id: number, status: number) {

    return this.http.put(`${environment.url}api/v1/dimension/${id}`, { status: status }, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (status == 0) {
            Swal.fire('Bien!', 'La Dimensión ha sido desactivada!', 'success')
          } else {
            Swal.fire('Bien!', 'La Dimensión ha sido activada!', 'success')
          }
        }),
        catchError(
          error => {
            Swal.fire(`Error ${error.status}`, error.error.message, 'error');
            this.notificacion.emit(error);
            return throwError(error.error.status);
          }
        ),
      );
  }

  public createDimensions(model: Dimension) {

    model.dimension = Number(model.dimension);

    return this.http.post<Dimension[]>(`${environment.url}api/v1/dimension`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          Swal.fire('Bien', 'La Dimension ha sido creada exitosamente!', 'success');
        }),
        catchError(error => {
          Swal.fire(`Error ${error.status}`, error.error.message, 'error');
          this.notificacion.emit(error);
          return throwError(error.error.status);
        }),
      );
  }

  public editarDimension(model: Dimension) {

    return this.http.put(`${environment.url}api/v1/dimension/${model.id}`, model, { observe: 'response' })
      .pipe(
        map((resp: any) => {
          this.notificacion.emit(resp);
          if (resp.body.message.status == 1) {
            Swal.fire('Editar dimension de flor', 'La dimension de flor ha sido editada, exitosamente', 'success');
          }
          return this.router.navigateByUrl('/varieties');
        }),
        catchError(error => this.errorHandlerService.showError(error))
      );
  }
  
}
