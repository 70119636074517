import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

// Services
import { UsersService } from '../services/pages/users/users.service';
import { Subscription } from 'rxjs';

//Funciones javascript
declare function init_plugins();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  private subscription = new Subscription();
  public loading: boolean;

  constructor(
    private usersService: UsersService
  ) { }

  ngOnInit() {
    init_plugins();
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
  }
  
  /*
  email: new FormControl('admin@admin.com', [Validators.required, Validators.email]),
  password: new FormControl('1234', [Validators.required])
  */

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public login() {
    this.loading = true;
    if (this.loginForm.valid) {
      this.subscription.add(this.usersService.login(
        this.loginForm.controls.email.value, 
        this.loginForm.controls.password.value).subscribe(resp => {
          this.loading = false;
        }));
    }
  }

}
