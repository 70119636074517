import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';
import { throwError, Observable } from 'rxjs';

// Interfaces 
import { Usuario, Category } from '../../../interfaces/interfaces.index';

// Services
import { ErrorHandlerService } from '../../shared/error-handler.service';

export interface Sub {
    sub: number;
}

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    public notificacion = new EventEmitter<any>();
    public id: string;
    public token: string;
    public token2: string;
    public usuario: Usuario;
    public users: any;
    public sub: Sub;
    public menu: Category[];
    public access: string;
    public baseStatics: string;
    public loading: boolean;
    public toggle: boolean;

    constructor(
        private http: HttpClient,
        private router: Router,
        private errorHandlerService: ErrorHandlerService
    ) {
        this.loadFromStorage();
    }

    public isLogged() { return this.token.length; }
    //Roles
    //Los numeros son referencias a la tabla BD. roles
    public isAdmin() { return this.users.rol === '1';} 
    public isManager() { return this.users.rol === '2'; }
    public isSupport() { return this.users.rol === '3'; }
    public isUser() { return this.users.rol === '4';}
    public isSeller() { return this.users.rol === '5'; }
    public getuserId() { return atob(this.id); }

    public loadFromStorage() {
        if (localStorage.getItem('secure')) {
            this.id = localStorage.getItem('id');
            this.token = localStorage.getItem('secure');
            this.token2 = localStorage.getItem('secure2');
            this.usuario = jwt_decode(this.token);
            this.users = jwt_decode(this.token);
            this.sub = jwt_decode(this.token);
            this.menu = JSON.parse(JSON.stringify(jwt_decode(localStorage.getItem('access'))));//menu
        } else {
            this.id = '';
            this.token = '';
            this.token2 = '';
            this.usuario = null;
            this.users = null;
            this.sub = null;
            this.menu = [];
        }
    }

    public saveInTheStorage(token: string, dataAccess: string, baseStatics:string, dataRoles:string, id:number) {

        localStorage.setItem('secure', token);
        localStorage.setItem('access', JSON.stringify(dataAccess));//menu
        localStorage.setItem('baseStatics', JSON.stringify(baseStatics));
        localStorage.setItem('references', JSON.stringify(dataRoles));
        localStorage.setItem('id', btoa(id.toString()));

        this.token = token;
        this.usuario = jwt_decode(token);
        this.menu = JSON.parse(JSON.stringify(jwt_decode(localStorage.getItem('access'))));
        //this.menu = menu;
    }
    public storage(resp:any){
        localStorage.setItem('secure2', resp.data.security);
        localStorage.setItem('session', resp.data.session);
        localStorage.setItem('base', resp.data.base); 
    }
/*
    public login2(email: string, password: string): Observable<any> {
        return this.http.post(`${environment.url}/api/v2/login`, { email, password })
            .pipe(
                map((resp: any) => {
                    this.notificacion.emit(resp);
                    this.storage(resp);
                    return true;
                }),
                catchError( error => {
                    return error;
                })
            );
    }
    */
    ///this.saveInTheStorage(resp.token, resp.menu);
    public login(email: string, password: string) {
        let retorno;
        /*
        retorno = this.http.post(`${environment.url}/api/v2/login`, { email, password })
            .pipe(
                map((resp: any) => {
                    this.notificacion.emit(resp);
                    this.storage(resp);
                }),
                catchError( error => {
                    error.status = "Disculpe!";
                    error.error.message ="Autenticación fallida y/o cuenta suspendida!";
                    return this.errorHandlerService.showErrorLogin(error);
                })
            );
            */
        retorno =  this.http.post(`${environment.url}/login`, { email, password })
        .pipe(
            map((resp: any) => {
                let data = resp.message.Authorization;
                let dataAccess = resp.message.access;
                let dataBase = resp.message.baseStatics;
                let dataRoles = resp.message.references;
                let id = resp.message.id;
                this.config();
                this.saveInTheStorage(data, dataAccess, dataBase, dataRoles, id);
                this.router.navigateByUrl('/home');
            }),
            catchError(error => {
                error.status = "Disculpe!";
                error.error.message ="Autenticación fallida y/o cuenta suspendida!";
                return this.errorHandlerService.showErrorLogin(error);
            })
        );
        return retorno;
    }

    public config(){
        this.toggle = false;
        let defaultConfig = {
            fixedFooter: false,
            fixedHeader: false,
            fixedLeftSidebar: true,
            isShrinked: true,
            themeColor: "app-theme-megaflor",
            themeMode: "default-mode",
        };
        //localStorage.setItem('ABCADMIN_CONFIG', JSON.stringify(defaultConfig));
    }

    public logout() {
        
        localStorage.removeItem('id');
        localStorage.removeItem('secure');
        localStorage.removeItem('access');
        localStorage.removeItem('baseStatics');
        localStorage.removeItem('references');
        localStorage.removeItem('pdfjs.history');//History pdfjs
        localStorage.removeItem('add_pay');
        localStorage.removeItem('pago');
        localStorage.removeItem('busqueda');
        localStorage.removeItem('selectedData');

        this.id = '';
        this.token = '';
        this.usuario = null;
        this.menu = [];
        this.baseStatics = '';
        this.router.navigateByUrl('/login');
    }

    public getAllUsers(): Observable<any> {
        return this.http.get<Usuario[]>(`${environment.url}api/v1/users`, { responseType: 'json' });
    }

    public createUsuario(model: Usuario) {
        return this.http.post<Usuario[]>(`${environment.url}api/v1/users`, model, { observe: 'response' })
            .pipe(
                map((resp: any) => {
                    this.notificacion.emit(resp);
                    Swal.fire('Bien', 'El usuario ha sido agregado', 'success');
                }),
                catchError(
                    error => {
                        Swal.fire(`Error ${error.status}`, error.error.message, 'error');
                        this.notificacion.emit(error);
                        return throwError(error.error.status);
                    }
                ),

            );
    }

    public activarDesactivarUsuario(idUsuario: number, status: number) {
        return this.http.put(`${environment.url}api/v1/users/${idUsuario}`, {status: status}, {observe: 'response'})
            .pipe(
                map((resp: any) => {
                    this.notificacion.emit(resp);
                    if(status == 0){
                        Swal.fire('Bien!', 'El usuario ha sido desactivado!', 'success')
                    }else{
                        Swal.fire('Bien!', 'El usuario ha sido activado!', 'success')
                    }
                }),
                catchError(
                    error => {
                        Swal.fire(`Error ${error.status}`, error.error.message, 'error');
                        this.notificacion.emit(error);
                        return throwError(error.error.status);
                    }
                ),
            );
    }

    public getUsuario(id: number) {

        return this.http.get<Usuario>(`${environment.url}api/v1/users/${id}`, {observe: 'response'})
        .pipe(
            map((resp: any) => {
                return resp.body.message;
            }),
            catchError(
                error => {
                    Swal.fire(`Error ${error.status}`, error.error.message, 'error');
                    this.notificacion.emit(error);
                    return throwError(error.error.status);
                }
            ),
        );
    }

    public editUsuario(model: Usuario) {

        return this.http.put(`${environment.url}api/v1/users/${model.id}`, model, { observe: 'response' })
            .pipe(
                map((resp: any) => {
                    Swal.fire('Editar Usuario', 'El usuario ha sido editado, exitosamente', 'success');
                    return this.router.navigateByUrl('/users');
                }),
                catchError(error => this.errorHandlerService.showError(error))
            );
    }

    public actualizarClave(idUs: number, password: string, oldpass: string) {
       
        return this.http.put(`${environment.url}api/v1/users/changes/${idUs}`, {password: password, oldpass: oldpass}, {observe: 'response'})
            .pipe(
                map((resp: any) => {
                    this.notificacion.emit(resp);
                    Swal.fire('Exito!', 'La contraseña se ha cambiado exitosamente!', 'success');
                    return this.router.navigateByUrl('/profile');
                }),
                catchError(
                    error => {
                        error.status = "Disculpe!";
                        error.error.message ="Ha omitido algún dato, por favor ingrese todos los datos";
                        return this.errorHandlerService.showErrorLogin(error);
                    }
                ),
            );
    }

























    public createUsers(model: Usuario): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.token
        });
        return this.http.post<Usuario[]>(`${environment.url}api/v1/users`, { model, headers, responseType: 'json' });
    }





    public getUsuarios(pageNumber: number = 1, pageSize: number = 10) {
        return this.http.get<Usuario[]>(`${environment.url}/usuarios/listar?pageNumber=${pageNumber}&pageSize=${pageSize}`,
            { observe: 'response' })
            .pipe(
                map((resp: any) => ({ usuarios: resp.body, pagination: JSON.parse(resp.headers.get('X-Pagination')) }),
                    catchError(error => this.errorHandlerService.showError(error))
                ));
    }

    

    public createUsuarios(model: Usuario) {
        return this.http.post<Usuario[]>(`${environment.url}/usuarios/crear`, model, { observe: 'response' })
            .pipe(
                map((resp: any) => {
                    Swal.fire('Usuario creado', 'El usuario ha sido agregado', 'success');
                    return { usuarios: resp.usuarios, pagination: JSON.parse(resp.headers.get('X-Pagination')) };
                }),
                catchError(error => this.errorHandlerService.showError(error))
            );
    }

    /*
    public editUsuario(model: Usuario) {
        return this.http.put(`${environment.url}/usuarios/actualizar`, model, { observe: 'response' })
            .pipe(
                map((resp: any) => {
                    if (this.usuario.id == model.id) {
                        Swal.fire('Editar Perfil',
                            'Tus datos han sido actualizados! Los cambios se verán reflejados la próxima vez que inicie sesión',
                            'success');
                        this.router.navigateByUrl('/dashboard');
                    } else {
                        Swal.fire('Editar Usuario', 'El usuario ha sido editado!', 'success');
                        this.router.navigateByUrl('/users');
                    }
                    return { usuarios: resp.body.usuarios, pagination: JSON.parse(resp.headers.get('X-Pagination')) };
                }),
                catchError(error => this.errorHandlerService.showError(error))
            );
    }
    */

    /*
    public uploadProfilePic(image: File) {
        const formData = new FormData();
        
        formData.append('name', image.name);
        formData.append('image', image);
        formData.append('idUser', this.usuario.id.toString());

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        return this.http.post<string>(`${environment.url}/usuarios/UploadProfilePic`, formData, { headers })
            .pipe(
                map((resp: any) => resp.imgUrl),
                catchError(error => this.errorHandlerService.showError(error))
            );
    }
    */

    public filtrarUsuarios(model: any, pageNumber: number = 1, pageSize: number = 10) {

        return this.http.post(`${environment.url}/usuarios/filtrar?pageNumber=${pageNumber}&pageSize=${pageSize}`, model, { observe: 'response' })
            .pipe(
                map((resp: any) => ({ usuarios: resp.body, pagination: JSON.parse(resp.headers.get('X-Pagination')) })),
                catchError(error => this.errorHandlerService.showError(error))
            );
    }

    public activarUsuario(idUsuario: number) {
        return this.http.put(`${environment.url}/usuarios/activar/${idUsuario}`, {})
            .pipe(
                map(() => Swal.fire('Activar usuario', 'El usuario ha sido activado!', 'success')),
                catchError(error => this.errorHandlerService.showError(error))
            );
    }



}
